//ui
import ReactPlayer from 'react-player/youtube'
//pages
import Header from "./pages/header";
import Footer from "./pages/footer";
import Banner from "./pages/Banner";
import ContactBanner from './pages/ContactBanner';

//features
import Auth from "./features/auth";
//sauce
import './App.css';
import clouds from './assets/clouds.svg';
import cloudsAgain from './assets/clouds.svg';
import FloatingDiamonds from './features/FloatingDiamonds';
import AboutUs from './pages/AboutUs';
import { Container, Paper, Box } from '@mui/material';
function App() {
  return (
    <div className="App" >
      
      <Header />
      <p>
        ♫₊˚⋆｡♪₊°♬˚.⁺ - dev sit - non-functional - everything will change -            
            ( -_•)╦̵̵̿╤─
      </p>

      <body className="App-body">
        <ContactBanner></ContactBanner>
        <img src={clouds} className="clouds" alt='clouds'/>
        <img src={cloudsAgain} className="cloudsAgain" alt='cloudsA'/>
        <auth className="auth">
        <Auth />
      </auth>       
      </body>

      <div className='player-text'>
      <p>Trending</p>
      <Container
        className="player-container">
        <Paper
          className="player-wrapper">
          <Box>
            <ReactPlayer
              className="player"
              url="https://www.youtube.com/watch?v=h0-WO6xRbAo"
              width="100%"
            />
          </Box>
        </Paper>
      </Container>
      </div>

    <Banner>
    </Banner>
    <FloatingDiamonds></FloatingDiamonds>
    <Footer></Footer>
    <AboutUs></AboutUs><br></br>

    </div>
    
  );
}

export default App;
