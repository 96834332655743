import './Banner.css'

export default function Banner() {
    return (
      <yo>
        <section className="banner-section">
          <div className="banner-content">
            <h1 className="banner-title">a very</h1>
            <h1 className="banner-title">social platform</h1>
            <h1 className="banner-title">artist : listener duality</h1>
            <p className="banner-text">
              +
            </p>
          </div>
          <div className="banner-overlay"></div>
        </section><br></br>
      </yo>
    );
  }