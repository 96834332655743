import React from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

const RegularSearchBar = () => {
  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    console.log('Search value:', searchValue);
    // Implement further actions here, such as filtering or searching
  };

  return (
    <div>
      <TextField
        label="Search"
        variant="outlined"
        position= "center"
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
      />
    </div>
  );
};

export default RegularSearchBar;
