import React from "react";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
//features
import SocialFollow from "../features/SocialFollow"
//sauce
import waveStripes from '../assets/waveStripes.svg';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="ssymmradio.co">
        ssymmradio.co
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Footer() {
  return (
    <div className="footer">
      <SocialFollow />
      <Copyright sx={{ mt: 1, mb: 2 }} />
      <img src={waveStripes} className="waveStripes"/>
    </div>
  );
}

export default Footer;