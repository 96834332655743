import React from 'react';
import './AboutUs.css'
import wild from '../assets/wildArt.svg';

const AboutUs = () => {
  return (
    <div>
      <div className="about-us-container" id='about-us-container'>
        <div className="about-us-content">
          <h1>About ssymm</h1>
          <span>Music</span>
          <span>Originality</span>
          <span>Harmony</span>
          <p>
          Cult.
          </p>
          <span>We are stronger together</span>
          <p>
          ssymm.dao.eth
          </p>
        </div>
      </div>
      <div className='wild-container'>
        <p>
          <img src={wild} className="wild_art" alt='wild' />
        </p>
      </div>
    </div>
  );
};

export default AboutUs;