import yolo from '../assets/yolo.svg';
import './HomeBanner.css'
export default function About() {
  return (
    <div className="home-banner" style={{ padding: 10}}>
    <h1>Future of France</h1>
    <p><b>ssymm</b> is an innovative and paradigm breaking music platform <br></br>
      for artist exposure and listener enjoyment.</p>

    <img src={yolo} className="yolo"/>

    </div>
  );
}