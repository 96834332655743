//import coreValues from '../assets/coreValues.svg';
//      <img src={coreValues} className="core-values"/>
import discordLogoShort from '../assets/discordLogoShort.svg';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import './ContactBanner.css';
import Button from '@mui/material/Button';

export default function Contact() {
  return (
    <div className="contact-banner" style={{ padding: 50 }}>
         
      <a target="_blank" href="https://discord.gg/nAtb6AjSpu" rel="noopener noreferrer">
        <img src={discordLogoShort} className="discordLogoShort" alt='disc'/>
      </a>
      <Button
        className='disc-button'
        type="submit"
        variant="contained"
        href='https://discord.gg/nAtb6AjSpu'
        color= "secondary"
        sx={{ mt: 3, mb: 2 }}
        >
        _-  Get in Touch  -_
        </Button>

    </div>
  );
}
