import "./FloatingDiamonds.css";

const FloatingDiamonds = () => {
  return (
    <div className="diamond-container">
      <div className="diamond"></div>
      <div className="diamond"></div>
      <div className="diamond"></div>
      <div className="diamond"></div>
      <div className="diamond"></div>

      {/* Add more diamonds as needed */}
    </div>
  );
};

export default FloatingDiamonds;
