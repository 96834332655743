import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import logo from '../assets/logo.png';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SearchBar from '../features/SearchBar';
import './header.css'
//import CssBaseline from '@mui/material/CssBaseline';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from 'react-router-dom';
import AboutUs from './AboutUs';
import HomeBanner from './HomeBanner';
import NoMatch from './404';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: green[500],
    },
  },
});

const scroll = () => {
  const section = document.querySelector( '#about-us-container' );
  section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
};

function ResponsiveAppBar() {
  return (
    <div>
    <ThemeProvider theme={theme}>    

      <Router>
        <AppBar position="static" className="app-header">
          <Container maxWidth="xl">
            
            <Toolbar disableGutters>
            <img
              src={logo}
              className="app_logo"
              alt="logo"
              width="10%" // Original width for web/desktop
              height="auto" // Auto height to maintain aspect ratio
              sx={{
                maxWidth: '40%', // Adjusted max-width for mobile (50% of the container width)
                maxHeight: '40vh', // Adjusted max-height for mobile (50% of the viewport height)
                '@media (max-width: 767px)': {
                  maxWidth: '30%', // Further adjusted max-width for smaller screens
                  maxHeight: '30vh', // Further adjusted max-height for smaller screens
                },
              }}
            />
            <Typography
              variant="h6"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                textDecoration: 'none',
              }}
            >
              ssymm
            </Typography>

              <SearchBar />
              <Button
                component={Link}
                to="/"
                sx={{
                  display: 'flex',
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  textDecoration: 'none',
                  fontSize: '1rem', // Original font size for web/desktop
                  '@media (max-width: 767px)': {
                    fontSize: '0.9rem', // Adjusted font size for mobile
                    padding: '8px 16px', // Adjusted padding for mobile
                  },
                }}
              >
              Home
            </Button>
            <Button
              component={Link}
              onClick={scroll}
              sx={{
                display: 'flex',
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                textDecoration: 'none',
                fontSize: '1rem', // Original font size for web/desktop
                '@media (max-width: 767px)': {
                  fontSize: '0.9rem', // Adjusted font size for mobile
                  padding: '8px 16px', // Adjusted padding for mobile
                },
              }}
            >
              About
            </Button>
            </Toolbar>
          </Container>
        </AppBar>
        <Routes>
          <Route exact path="/" element={<HomeBanner />} />
          <Route exact path="*" element={<NoMatch />} />
        </Routes>
      </Router>
    </ThemeProvider>
    </div>

  );
}

export default ResponsiveAppBar;
